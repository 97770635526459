import React from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./src/theme/global-styles";
import SpriteSvg from "./src/images/assets/sprite.svg";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <div
      style={{ width: "0", height: "0", position: "absolute", top: "-500px" }}
    >
      <SpriteSvg />
    </div>
    {element}
  </ThemeProvider>
);
