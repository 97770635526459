import { createGlobalStyle } from "styled-components";

export const theme = {
  device: {
    /* 
      default: width > 1200 & width < 1800
      tabland: width < 1200
      tabport: width < 900
      phone:   width < 600
      deskbig: width > 1800
    */
    tabland: `(max-width: 75em)`,
    tabport: `(max-width: 56.25em)`,
    phone: `(max-width: 37.5em)`,
    deskbig: `(min-width: 112.5em)`,
  },
  font: {
    sans: `'Poppins', sans-serif`,
    serif: `'Arvo', serif`,
  },
  fontSizes: ["1rem", "1.125rem", "1.5rem", "1.75rem", "2em", "3em"],
  spaces: ["0.25em", "0.5em", "0.75em", "1em", "2em", "4em", "8em"],
  fontWeight: {
    light: "300",
    normal: "400",
    semibold: "600",
    bold: "700",
  },
  colors: {
    primary: "#5d55fa" /* Majorelle Blue */,
    secondary: "#40E0D0" /* Turquoise */,
    blacks: ["#000000", "#0d0d0d", "#131313", "#262626", "#333333"],
    whites: ["#ffffff", "#f5f5fa", "#f2f2f2", "#dedede"],
    blue: "#000080" /* Navy Blue */,
    red: "#ed1c24" /* Red Pigment */,
  },
  lineHeight: {
    none: 0,
    3: 3,
  },
  contentWidth: "",
};

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }

  html {
    /*  
      default: 1 rem = 10px;  10/16*100   = 62.5%    (width > 1200 & width < 1800)
      tabland: 1 rem = 14px,   9/16*100   = 56.25%   (width < 1200)
      tabport: 1 rem = 13px,   8/16*100   = 50%  (width < 900)
      phone:   1 rem = 12px,   7/16*100   = 43.75%     (width < 600)
      deskbig: 1 rem = 18px,  12/16*100   = 75%  (width > 1800)
    */

    font-size: 62.5%;

    @media ${({ theme }) => theme.device.tabland} {
        font-size: 56.25%;
    }

    @media ${({ theme }) => theme.device.tabport} {
        font-size: 50%;
    }

    @media ${({ theme }) => theme.device.phone} {
        font-size: 43.75%;
    }

    @media ${({ theme }) => theme.device.deskbig} { 
        font-size: 75% 
    }

    scroll-behavior: smooth;
  }

  body {
    font-family: ${({ theme }) => theme.font.sans};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blacks[3]};
    box-sizing: border-box;
    line-height: 1.6;
    overflow-x: hidden;

    font-size: 1.6rem;

    @media ${({ theme }) => theme.device.tabland} {
      font-size: 1.8rem;
    }

    @media ${({ theme }) => theme.device.tabport} {
      font-size: 2.1rem;
    }

    @media ${({ theme }) => theme.device.phone} {
      font-size: 2.3rem;
    }

    @media ${({ theme }) => theme.device.deskbig} {
      font-size: 2.1rem;
    }
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  ul, ol, li {
	  list-style: none;
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 3.1rem;

    @media ${({ theme }) => theme.device.tabland} {
      font-size: 3.3rem;
    }

    @media ${({ theme }) => theme.device.phone} {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 1.8rem;

    @media ${({ theme }) => theme.device.tabland} {
      font-size: 2.1rem;
    }

    @media ${({ theme }) => theme.device.phone} {
      font-size: 2.5rem;
    }
  }
`;
